import React from 'react';

import SEO from '../components/seo';

const Share = (props) => {

  return (
    <>
      <SEO title="Faça sua parte também" />

      <div className="share-container" style={{ backgroundImage: `url(${require('../images/backgrounds/section-dots-bg.svg')})` }}>
        <a href="#" className="share-link">
          <img src="https://d3tkwokssgv28o.cloudfront.net/wp-content/uploads/2010/07/15063358/uniquely-me-large-thumb-500x500.jpg" alt="Imagem de compartilhamento" className="share-image"/>
        </a>
      </div>
    </>
  );

};

export default Share;
